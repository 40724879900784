import { graphql } from 'gatsby';

import ActivityCard from '../../components/compound/ActivityCard';
import { isValidArray, getKey } from '../../helpers';

/**
 * activitycard widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function activitycard(props, widgetType) {
  const activities = isValidArray(props.activities) && props.activities.map(activity => ({
    description: getKey(activity, 'description'),
    event: `/eventos/${getKey(activity, 'event.slug')}`,
    eventDay: getKey(activity, 'eventDay'),
    eventMonth: getKey(activity, 'eventMonth'),
    eventYear: getKey(activity, 'eventYear'),
    image: getKey(activity, 'image.childImageSharp.fluid'),
    link: getKey(activity, 'link'),
    title: getKey(activity, 'title'),
  }));

  return {
    Component: ActivityCard,
    activities,
    widgetOrder: getKey(props, 'widgetOrder'),
    widgetType,
  };
}

/**
 * GraphQL Query
 */
export const query = graphql`
  fragment ActivityCard on StrapiPagesWidgets {
    activitycard {
      key: id,
      widgetOrder
      activities {
        title
        eventDay: date(formatString: "D")
        eventMonth: date(formatString: "MMMM", locale: "es")
        eventYear: date(formatString: "YYYY")
        description
        event {
          slug
        }
        link
        image {
          childImageSharp {
            fluid(maxWidth: 300, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
