import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';

import { setActiveSlide } from '../../../../store/actions';
import { useIsInView } from '../../../../helpers/hooks';
import ImageFluid from '../../../base/ImageFluid';
import ImageFluidWrapper from '../../../base/ImageFluidWrapper';
import Text from '../../../base/Text';

import Styles from './TypeA.styles';
import Link from '../../../base/Link';
import { isValidArray, getKey, isAbsoluteUrl } from '../../../../helpers';

const ImageWrapper = styled.div`
  ${Styles.imageWrapper}
`;
const ContentWrapper = styled.div`
  ${Styles.contentWrapper}
`;
const Wrapper = styled.div`
  ${Styles.wrapper}
`;
const PlaceHolderDesktop = styled(ImageFluid)`
  ${Styles.placeHolderDesktop}
`;
const PlaceHolderMobile = styled(ImageFluid)`
  ${Styles.placeHolderMobile}
`;
const Title = styled(Text)`
  ${Styles.title}
`;
const Description = styled(Text)`
  ${Styles.description}
`;
const ArrowWrapper = styled.svg`
  ${Styles.arrowWrapper}
`;
const CallToActionWrapper = styled.div`
  ${Styles.callToActionWrapper}
`;
const CallToAction = styled(Link)`
  ${Styles.callToAction}
`;
const Content = styled.div`
  ${Styles.content}
`;

/**
 * ActionCard Type A component
 * @param {Object} props - props
 * @returns {JSX}
 */
const TypeA = ({
  backgroundColor,
  backgroundImage,
  backgroundImageMobile,
  callToActionHighlightColor,
  description,
  descriptionColor,
  index,
  links,
  textAlignment,
  title,
  titleColor,
}) => {
  const dispatch = useDispatch();
  const [ref, isIntersecting] = useIsInView();

  const theme = {
    backgroundColor,
    callToActionHighlightColor,
    descriptionColor,
    textAlignment,
    titleColor,
  };

  const callToActions = useMemo(() => {
    if (!isValidArray(links)) {
      return null;
    }

    const linksLength = links.length - 1;

    return links.map((link, idx) => {
      const linkTitle = getKey(link, 'label', getKey(link, 'title'));
      const uri = getKey(link, 'externalPage', getKey(link, 'uri'));
      const isLast = idx === linksLength && idx % 2 === 0;
      const preloadDisabled = isAbsoluteUrl(uri);

      return (
        <CallToAction
          href={uri}
          key={link.key}
          preloadDisabled={preloadDisabled}
          isLast={isLast}
        >
          {linkTitle}
        </CallToAction>
      );
    });
  }, [links]);

  if (isIntersecting) {
    dispatch(setActiveSlide(index));
  }

  return (
    <Wrapper ref={ref}>
      <ThemeProvider theme={theme}>
        <ImageWrapper>
          <ImageFluidWrapper>
            <PlaceHolderDesktop fluid={backgroundImage} />
            <PlaceHolderMobile fluid={backgroundImageMobile} />
          </ImageFluidWrapper>
        </ImageWrapper>
        <ContentWrapper>
          <div>
            <ArrowWrapper>
              <rect width="11" height="9" x="2020" y="21" fill={backgroundColor} />
              <rect width="2020" height="22" x="2031" y="8" fill={backgroundColor} />
              <rect width="2020" height="22" y="8" fill={backgroundColor} />
              <rect width="2000" height="8" x="2051" fill="#FFF" />
              <rect width="2000" height="8" fill="#FFF" />
              <path fill={callToActionHighlightColor} d="M1999.51806,0 C2016.04888,0 2029.49722,13.457911 2029.49722,30 L2021.63633,30 C2021.63633,17.7954603 2011.71392,7.86598543 1999.51806,7.86598543 L1999.51806,0 Z" />
              <path fill="#FFF" d="M2051.48204,0 L2051.48204,7.86598543 C2039.28614,7.86598543 2029.3637,17.7954603 2029.3637,30 L2021.50278,30 C2021.50278,13.457911 2034.95116,0 2051.48204,0" />
            </ArrowWrapper>
          </div>
          <div className="container">
            <div className="row">
              <Content className="col-12">
                <Title>{title}</Title>
                <Description>{description}</Description>
                {callToActions && (
                  <CallToActionWrapper>
                    {callToActions}
                  </CallToActionWrapper>
                )}
              </Content>
            </div>
          </div>
        </ContentWrapper>
      </ThemeProvider>
    </Wrapper>
  );
};

TypeA.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  callToActionHighlightColor: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  index: PropTypes.number,
  links: PropTypes.array,
  textAlignment: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
};

export default TypeA;
