import { css } from 'styled-components';

export default {
  video: css`
    height: 100vh;
    left: 0;
    max-height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    width: 100%;
  `,
};
