import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from '../../../base/Text';
import Styles from './SectionButton.styles';

const StyledButton = styled.div`
  ${Styles.styledButton}
`;
const Line = styled.div`
  ${Styles.line}
`;

/**
 * Section button component
 * @param {Object} props of the component
 * @param {Object} ref reference
 * @returns {JSX}
 */
const SectionButton = ({
  enableLine,
  gradient,
  isActive,
  label,
  onClick
}, ref) => (
  <StyledButton
    onClick={onClick}
    isActive={isActive}
    color={gradient.start}
  >
    <Text bold>{label}</Text>
    {(isActive && enableLine) && <Line gradient={gradient} ref={ref} />}
  </StyledButton>
);

SectionButton.propTypes = {
  enableLine: PropTypes.bool,
  gradient: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default React.forwardRef(SectionButton);
