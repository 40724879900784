import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { getKey } from '../helpers';
import Widgets from '../factories/index';
import StaticPage from '../components/compound/StaticPage';

/**
 * Page Component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const Page = props => {
  const isStaticPage = getKey(props, 'data.widgets.edges[0].node.isStaticPage', false);
  const staticContent = getKey(props, 'data.widgets.edges[0].node.staticContent', {});
  const pageTitle = getKey(props, 'data.widgets.edges[0].node.title');
  const widgets = getKey(props, 'data.widgets.edges[0].node.widgets', {});
  const metaTitle = getKey(props, 'pageContext.metaTitle');
  const location = getKey(props, 'location');
  const backgroundImage = getKey(props, 'data.widgets.edges[0].node.backgroundImage.childImageSharp.fluid');
  const backgroundImageMobile = getKey(props, 'data.widgets.edges[0].node.backgroundImageMobile.childImageSharp.fluid');
  const hasContactForm = getKey(props, 'data.widgets.edges[0].node.hasContactForm');

  return (
    <>
      {metaTitle && (
        <Helmet
          title={`Alestra - ${metaTitle}`}
          meta={[
            { content: metaTitle, name: 'description' },
            { content: `${metaTitle}, alestra`, name: 'keywords' },
            { content: 'axej1rm54yj7ys3giz3ghb7ydb5voc', name: 'facebook-domain-verification' }
          ]}
        >
          <html lang="es" />
        </Helmet>
      )}
      {!isStaticPage && <Widgets widgets={widgets} location={location} />}
      {isStaticPage && (
        <StaticPage
          content={staticContent}
          title={pageTitle}
          widgets={widgets}
          location={location}
          backgroundImage={backgroundImage}
          backgroundImageMobile={backgroundImageMobile}
          hasContactForm={hasContactForm}
        />
      )}
    </>
  );
};

export default Page;

export const query = graphql`
  query WidgetsQuery($pageID: String!) {
    widgets: allStrapiPages(filter: { id: { eq: $pageID } }) {
      edges {
        node {
          isStaticPage
          staticContent
          hasContactForm
          title
          widgets {
            ...Widgets
          }
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          backgroundImageMobile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
