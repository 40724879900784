import { graphql } from 'gatsby';

import { getKey } from '../../helpers';
import ContentCard from '../../components/compound/ContentCard';

/**
 * contentcard widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function contentcard (props, widgetType) {
  return {
    Component: ContentCard,
    backgroundImage: getKey(props, 'backgroundImage.childImageSharp.fluid'),
    backgroundImageMobile: getKey(props, 'backgroundImageMobile.childImageSharp.fluid'),
    cardGradient: getKey(props, 'cardGradient'),
    content: getKey(props, 'content'),
    contentSettings: getKey(props, 'contentSettings'),
    gradient: getKey(props, 'gradient'),
    id: getKey(props, 'key'),
    isVerticalSlide: getKey(props, 'isVerticalSlide'),
    key: getKey(props, 'key'),
    link: {
      title: getKey(props, 'link.title'),
      uri: getKey(props, 'link.page.uri'),
    },
    showSlideTitle: getKey(props, 'showSlideTitle', false),
    title: getKey(props, 'title'),
    titleSettings: getKey(props, 'titleSettings'),
    widgetOrder: getKey(props, 'widgetOrder'),
    widgetType,
  };
}

/**
 * GraphQL Query
 */
export const query = graphql`
  fragment ContentCard on StrapiPagesWidgets {
    contentcard {
      key: id
      widgetOrder
      title
      content: content_md
      titleSettings: titleColumns {
        columns
        offset: offsetColumns
      }
      contentSettings: contentColumns {
        columns
        offset: offsetColumns
      }
      link {
        title: label
        page {
          uri: slug
        }
      }
      isVerticalSlide
      showSlideTitle
      backgroundImage {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImageMobile {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gradient {
        id
        end
        start
      }
      cardGradient {
        id
        end
        start
      }
    }
  }
`;
