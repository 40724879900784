import actioncard from './mapping/actioncard';
import activitycard from './mapping/activitycard';
import entrypointcard from './mapping/entrypointcard';
import headercard from './mapping/headercard';
import solutionscard from './mapping/solutionscard';
// import stepscard from './mapping/stepscard';
import contentcard from './mapping/contentcard';
import contactform from './mapping/contactform';
import contactformextended from './mapping/contactformextended';
import internalnavigation from './mapping/internalnavigation';

/**
 * Map components props
 * @param {string} widget - widget type
 * @param {Object} props - widget props
 * @returns {Object}
 */
export default function mapWidgetProps(widget, props) {
  switch (widget) {
    case 'actioncard':
      return actioncard(props, widget);
    case 'activitycard':
      return activitycard(props, widget);
    case 'entrypointcard':
      return entrypointcard(props, widget);
    case 'headercard':
      return headercard(props, widget);
    case 'solutionscard':
      return solutionscard(props, widget);
    // case 'stepscard':
    //   return stepscard(props, widget);
    case 'contentcard':
      return contentcard(props, widget);
    case 'contactform':
      return contactform(props, widget);
    case 'contactformextended':
      return contactformextended(props, widget);
    case 'internalnavigation':
      return internalnavigation(props, widget);
    default:
      return null;
  }
}
