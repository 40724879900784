import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isValidArray } from '../../../helpers';

import Activity from './Activity';
import Styles from './ActivityCard.styles';

const Wrapper = styled.div`${Styles.wrapper}`;

/**
 * Activity card
 * @returns {JSX}
 */
const ActivityCard = ({
  activities,
}) => {
  const renderActivities = useMemo(() => {
    if (!isValidArray(activities)) return null;

    return activities.map(item => (
      <div className="col-12 col-md-4">
        <Activity {...item} />
      </div>
    ));
  }, [activities]);

  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          {renderActivities}
        </div>
      </div>
    </Wrapper>
  );
};

ActivityCard.propTypes = {
  activities: PropTypes.array,
};

export default ActivityCard;
