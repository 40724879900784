import React, {
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';

import { validateInputs, getKey } from '../../../helpers';
import InputField from '../../base/InputField';
import Text from '../../base/Text';
import Styles from './ContactClientsForm.styles';

const Content = styled.div`${Styles.content}`; // prettier-ignore
const ContentWrapper = styled.div`${Styles.contentWrapper}`; // prettier-ignore
const Description = styled(Text)`${Styles.description}`; // prettier-ignore
const Loading = styled.div`${Styles.loading}`; // prettier-ignore
const Submit = styled.button`${Styles.submit}`; // prettier-ignore
const SubmitMask = styled.div`${Styles.submitMask}`; // prettier-ignore
const SuccessMessage = styled(Text)`${Styles.successMessage}`; // prettier-ignore
const Title = styled(Text).attrs({ bold: true })`${Styles.title}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

const INPUTS_INITIAL = {
  empresa: {
    status: 0,
    value: '',
  },
  lastName: {
    status: 0,
    value: ''
  },
  mail: {
    status: 0,
    value: '',
  },
  mensaje: {
    status: 0,
    value: '',
  },
  name: {
    status: 0,
    value: '',
  },
};

/**
 * ContactClientsForm component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const ContactClientsForm = ({
  description,
  formId,
  portalId,
  title,
}) => {
  const nameRef = useRef();
  const lastNameRef = useRef();
  const mailRef = useRef();
  const mensajeRef = useRef();
  const [validForm, setValidForm] = useState(false);
  const [sending, setSending] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [inputs, setInputs] = useState(INPUTS_INITIAL);

  const handleInputChange = useCallback(event => {
    event.persist();
    const {
      target: { name, type, value }
    } = event;

    setInputs(inputsValues => ({
      ...inputsValues,
      [name]: {
        status: validateInputs(value, type) ? 1 : 2,
        value,
      }
    }));
  }, []);

  const hasName = getKey(inputs, 'name.status', 0) === 1;
  const hasLastName = getKey(inputs, 'lastName.status', 0) === 1;
  const hasPhone = getKey(inputs, 'phone.status', 0) === 1;
  const hasMail = getKey(inputs, 'mail.status', 0) === 1;
  const hasNumClient = getKey(inputs, 'numeroDeCliente.status', 0) === 1;
  const hasMensaje = getKey(inputs, 'mensaje.status', 0) === 1;

  useEffect(() => {
    if (hasName && hasLastName && hasPhone && hasMail && hasNumClient && hasMensaje) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [inputs, hasName, hasLastName, hasPhone, hasMail, hasNumClient, hasMensaje]);

  const focusForm = useCallback(() => {
    if (!hasName) {
      nameRef.current.focus();
    } else if (!hasLastName) {
      lastNameRef.current.focus();
    } else if (!hasMail) {
      mailRef.current.focus();
    } else if (!hasMensaje) {
      mensajeRef.current.focus();
    }
  }, [hasName, hasLastName, hasMail, hasMensaje]);

  const handleSubmit = useCallback(event => {
    if (event) {
      event.preventDefault();

      if (validForm) {
        setSending(true);
      } else {
        focusForm();
      }
    }
  }, [validForm, focusForm]);

  useEffect(() => {
    if (sending) {
      axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
          {
            fields: [
              {
                name: 'firstname',
                value: inputs.name.value,
              },
              {
                name: 'lastname',
                value: inputs.lastName.value,
              },
              {
                name: 'email',
                value: inputs.mail.value
              },
              {
                name: 'comentarios',
                value: inputs.mensaje.value,
              }
            ],
            skipValidation: true,
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then(({ data }) => {
          setSuccessStatus(true);
          setSending(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          setSending(false);
          setSuccessStatus(false);
          setInputs(INPUTS_INITIAL);
        });
    }
  }, [sending, portalId, formId, inputs]);

  return (
    <Wrapper>
      <form className="container" onSubmit={handleSubmit}>
        <ContentWrapper className="row">
          <Content className="col-12 col-md-4" description>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Content>
          <Content className="col-12 col-md-8" fields>
            <div className="row">
              <InputField
                className="col-12 col-md-6"
                placeHolder="Nombre"
                onChange={handleInputChange}
                value={inputs.name.value}
                name="name"
                reference={nameRef}
                fixAutofill="transparent"
                status={inputs.name.status}
              />
              <InputField
                className="col-12 col-md-6"
                placeHolder="Apellido"
                onChange={handleInputChange}
                value={inputs.lastName.value}
                name="empresa"
                reference={lastNameRef}
                fixAutofill="transparent"
                status={inputs.lastName.status}
              />
              <InputField
                className="col-12"
                placeHolder="Correo"
                onChange={handleInputChange}
                value={inputs.mail.value}
                name="mail"
                type="email"
                reference={mailRef}
                fixAutofill="transparent"
                status={inputs.mail.status}
              />
              <InputField
                className="col-12"
                placeHolder="Comentarios"
                onChange={handleInputChange}
                value={inputs.mensaje.value}
                name="mensaje"
                type="text"
                reference={mensajeRef}
                fixAutofill="transparent"
                status={inputs.mensaje.status}
              />
              <SubmitMask className="col-12">
                {(sending)
                  ? <Loading />
                  : !successStatus && <Submit type="submit" isActive={validForm}>Enviar</Submit>}
                {successStatus && (
                  <SuccessMessage>¡Muchas gracias!</SuccessMessage>
                )}
              </SubmitMask>
            </div>
          </Content>
        </ContentWrapper>
      </form>
    </Wrapper>
  );
};

ContactClientsForm.propTypes = {
  description: PropTypes.string,
  formId: PropTypes.string,
  portalId: PropTypes.string,
  title: PropTypes.string,
};

ContactClientsForm.defaultProps = {
  description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit vehicula, vivamus cum pharetra habitant volutpat habitasse morbi vel hendrerit.',
  title: 'Escríbenos',
};

export default ContactClientsForm;
