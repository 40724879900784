import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';

import Text from '../../../base/Text';

import Styles from './Activity.styles';

const Container = styled.div`${Styles.container}`;
const Title = styled(Text)`${Styles.title}`;
const EventDate = styled(Text)`${Styles.date}`;
const Description = styled(Text)`${Styles.description}`;

/**
 * Activity
 * @returns {JSX}
 */
const Activity = ({
  description,
  event,
  eventDay,
  eventMonth,
  eventYear,
  image,
  link,
  title,
}) => {
  const eventLink = event || link;

  return (
    <Container>
      <a href={eventLink}>
        <Image fluid={image} />
      </a>
      <Title tag="h3">
        <a href={eventLink}>
          {title}
        </a>
      </Title>
      <EventDate tag="p">
        {`${eventDay} de ${eventMonth}, ${eventYear}`}
      </EventDate>
      <Description tag="p">
        {description}
      </Description>
    </Container>
  );
};

Activity.propTypes = {
  description: PropTypes.string,
  event: PropTypes.string,
  eventDay: PropTypes.string,
  eventMonth: PropTypes.string,
  eventYear: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
  title: PropTypes.string,
};

export default Activity;
