import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isValidArray } from '../../../../helpers';

import SolutionItem from '../SolutionItem';
import Styles from './SolutionsContainer.styles';

const Wrapper = styled.div`
  ${Styles.wrapper}
`;

const ItemContainer = styled.div`
  ${Styles.itemContainer}
`;

const Line = styled.div`
  ${Styles.line}
`;

/**
 * Solutions container
 * @param {Array} contents of the solutions
 * @param {Object} lineRef line reference
 * @returns {JSX}
 */
const SolutionsContainer = ({
  contents,
  enableLine,
  gradient,
  isActive,
  location,
}, lineRef) => {
  if (!isValidArray(contents)) return null;

  return (
    <Wrapper isActive={isActive}>
      <div className="row">
        {
          contents.map(item => (
            <ItemContainer className="col-6 col-md-3" key={item.id}>
              <SolutionItem
                {...item}
                color={gradient.start}
                gradient={gradient}
                location={location}
              />
            </ItemContainer>
          ))
        }
        {enableLine && <Line gradient={gradient} ref={lineRef} />}
      </div>
    </Wrapper>
  );
};

SolutionsContainer.propTypes = {
  contents: PropTypes.array,
  enableLine: PropTypes.bool,
  gradient: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  location: PropTypes.object,
};

export default React.forwardRef(SolutionsContainer);
