import React, {
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isValidArray, getKey } from '../../../helpers';

import Text from '../../base/Text';
import SectionButton from './SectionButton';
import SolutionsContainer from './SolutionsContainer';
import Styles from './SolutionsCard.styles';
import ContactForm from '../ContactFormSolutions';

const Container = styled.div`
  ${Styles.container}
`;
const DesktopMask = styled.div.attrs({ className: 'col-12 col-md-9' })`
  ${Styles.desktopMask}
`;
const MainTitle = styled(Text).attrs({ tag: 'h2' })`
  ${Styles.mainTitle}
`;
const MobileMask = styled.div`
  ${Styles.mobileMask}
`;
const LineConnector = styled.div`
  ${Styles.lineConnector}
`;

/**
 * Solutions Card
 * @returns {JSX}
 */
const SolutionsCard = ({
  contents,
  formId,
  formInputValue,
  formLabel,
  formOptions,
  formPortalId,
  location,
  title,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [lineColor, setLineColor] = useState(null);
  const [lineLeft, setLineLeft] = useState(0);
  const [lineHeight, setLineHeight] = useState(2);
  const enableLine = contents.length > 1;

  /**
   * OnClick Handler
   * @param {Object} e - event
   * @param {integer} idx - index clicked
   * @param {Object} gradient - gradient of the element
   */
  const handleOnClick = (e, idx, gradient) => {
    setActiveIndex(idx);
    setLineColor(gradient.start);
  };

  const buttonsRef = useRef([]);
  if (contents.length > 0) {
    buttonsRef.current = Array(contents.length)
      .fill()
      .map((_, i) => buttonsRef.current[i] || createRef());
  }

  const renderContents = useMemo(() => {
    if (!isValidArray(contents)) return null;

    setLineColor(contents[0].gradient.start || '#fff');

    return contents.map((c, idx) => (
      <div
        key={c.id}
      >
        <SectionButton
          label={c.name}
          isActive={idx === activeIndex}
          gradient={c.gradient}
          onClick={e => handleOnClick(e, idx, c.gradient)}
          ref={buttonsRef.current[idx]}
          enableLine={enableLine}
        />
        <MobileMask>
          <SolutionsContainer
            contents={c.solutions}
            color={c.color}
            isActive={idx === activeIndex}
            gradient={c.gradient}
            location={location}
          />
        </MobileMask>
      </div>
    ));
  }, [activeIndex, contents, location, enableLine]);

  const lineContainerRef = useRef();
  const renderDesktopContainer = useMemo(() => {
    const solution = getKey(contents, `${activeIndex}.solutions`);
    const color = getKey(contents, `${activeIndex}.color`);
    const gradient = getKey(contents, `${activeIndex}.gradient`);
    if (!solution) return null;

    return (
      <SolutionsContainer
        contents={solution}
        color={color}
        gradient={gradient}
        location={location}
        isActive
        enableLine={enableLine}
        ref={lineContainerRef}
      />
    );
  }, [activeIndex, contents, location, enableLine]);

  const renderForm = useMemo(() => {
    if (!formId || !formPortalId || !isValidArray(formOptions) || !formInputValue) {
      return null;
    }

    return (
      <ContactForm
        title={`Somos expertos; acércate a nosotros y asesórate sobre ${formLabel || 'Nuestras Soluciones'}`}
        description="¿Necesitas asesoría, te interesa mas información sobre alguna de nuestras soluciones o simplemente quieres contactarnos? No lo dudes y escríbenos, nuestros expertos te están esperando."
        formId={formId}
        portalId={formPortalId}
        formOptions={formOptions}
        formInputValue={formInputValue}
      />
    );
  }, [formLabel, formId, formPortalId, formOptions, formInputValue]);

  useEffect(() => {
    if (enableLine) {
      const { top } = lineContainerRef.current.getBoundingClientRect();
      const { bottom, right } = buttonsRef.current[activeIndex]
        .current
        .getBoundingClientRect();

      setLineLeft(right);
      setLineHeight(bottom - top);
    }
  }, [activeIndex, buttonsRef, lineContainerRef, enableLine]);

  return (
    <>
      <Container>
        <LineConnector color={lineColor} left={lineLeft} height={lineHeight} />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-11">
              <MainTitle tag="h2">{title}</MainTitle>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              {renderContents}
            </div>
            <DesktopMask>
              {renderDesktopContainer}
            </DesktopMask>
          </div>
        </div>
      </Container>
      {renderForm}
    </>
  );
};

SolutionsCard.propTypes = {
  contents: PropTypes.array,
  formId: PropTypes.string,
  formInputValue: PropTypes.string,
  formLabel: PropTypes.string,
  formOptions: PropTypes.array,
  formPortalId: PropTypes.string,
  location: PropTypes.object,
  title: PropTypes.string,
};

export default SolutionsCard;
