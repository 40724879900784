import { graphql } from 'gatsby';

import { getKey } from '../../helpers';
// import ContactForm from '../../components/compound/ContactForm';
import FormWrapper from '../../components/compound/FormWrapper';

/**
 * contactform widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function contactform (props, widgetType) {
  return {
    Component: FormWrapper,
    description: getKey(props, 'description'),
    formId: getKey(props, 'formId'),
    formName: getKey(props, 'name'),
    formType: getKey(props, 'formType'),
    id: getKey(props, 'key'),
    key: getKey(props, 'key'),
    portalId: getKey(props, 'portalId'),
    title: getKey(props, 'title'),
    widgetType,
  };
}

/**
 * GraphQL Query
 */
export const query = graphql`
  fragment ContactForm on StrapiPagesWidgets {
    contactform {
      description
      key: id
      name
      portalId
      formId
      formType
      title
    }
  }
`;
