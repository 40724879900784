import { css } from 'styled-components';
import { WHITE } from '../../../../constants/colors';

import { rem, media } from '../../../../helpers/mixins';

/**
 * hides/display border
 * @param {string} startColor - start gradient color
 * @param {string} endColor - end gradient color
 * @returns {string}
 */
const shouldDisplayBorder = (startColor, endColor) => {
  if (startColor !== 'transparent' && endColor !== 'transparent') {
    return css`border: none;`;
  }

  return null;
};

export default {
  circle: ({ endColor, shouldDisplay, startColor }) => css`
    border: 1px solid ${WHITE};
    border-radius: 100%;
    cursor: pointer;
    height: 11px;
    position: relative;
    width: 11px;

    ${shouldDisplay && css`
      background: linear-gradient(to right, ${startColor} 0%, ${endColor} 100%);

      ${shouldDisplayBorder(startColor, endColor)}
    `}

    &:hover {
      background: linear-gradient(to right, ${startColor} 0%, ${endColor} 100%);
      ${shouldDisplayBorder(startColor, endColor)}

      > div {
        display: block !important;
      }
    }
  `,
  elements: css`
    display: flex;
    flex-direction: column;
  `,
  line: css`
    background-color: ${WHITE};
    height: 16px;
    margin-left: 5px;
    width: 1px;
  `,
  mainWrapper: css`
    color: white;
    display: none;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 11px;
    z-index: 3;

    ${media.md`
      display: flex;
      margin-left: calc(((100vw - 768px) / 2) + 24px);
    `}

    ${media.lg`
      margin-left: calc(((100vw - 1024px) / 2) + 24px);
    `}
  `,
  title: ({ shouldDisplay }) => css`
    display: none;
    font-size: ${rem('9px')};
    left: 24px;
    position: absolute;
    text-transform: uppercase;
    top: -2px;

    ${shouldDisplay && media.lg`
      display: block;
    `}
  `,
  wrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 11px;
  `,
};
