import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from '../../../base/Link';
import Styles from './SolutionItem.styles';

const LinkComponent = styled(Link)`
  ${Styles.linkComponent}
`;
const Container = styled.div`
  ${Styles.container}
`;

/**
 * Solution item
 * @returns {JSX}
 */
const SolutionItem = ({
  color,
  gradient,
  location,
  name,
  slug,
}) => {
  return (
    <Container>
      <LinkComponent
        color={color}
        gradient={gradient}
        href={`/soluciones/${slug}`}
        state={{
          from: location.pathname,
        }}
      >
        {name}
      </LinkComponent>
    </Container>
  );
};

SolutionItem.propTypes = {
  color: PropTypes.string,
  gradient: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
  }),
  location: PropTypes.object,
  name: PropTypes.string,
  slug: PropTypes.string,
};

export default SolutionItem;
