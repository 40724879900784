import React, {
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';

import { validateInputs, getKey, getRemoteAddress } from '../../../helpers';
import useContactCategories, { CLIENT_CATEGORIES, CARGO_CATEGORIES } from '../../../helpers/hooks/queries/contactCategories';
import InputField from '../../base/InputField';
import OptionsInput from '../../base/OptionsInput';
import Text from '../../base/Text';
import Styles from './ContactFormTech.styles';

const Content = styled.div`${Styles.content}`; // prettier-ignore
const ContentWrapper = styled.div`${Styles.contentWrapper}`; // prettier-ignore
const Description = styled(Text)`${Styles.description}`; // prettier-ignore
const Loading = styled.div`${Styles.loading}`; // prettier-ignore
const Options = styled(OptionsInput)`${Styles.options}`; // prettier-ignore
const Submit = styled.button`${Styles.submit}`; // prettier-ignore
const SubmitMask = styled.div`${Styles.submitMask}`; // prettier-ignore
const SuccessMessage = styled(Text)`${Styles.successMessage}`; // prettier-ignore
const Title = styled(Text).attrs({ bold: true })`${Styles.title}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore
const CheckLabel = styled.label`${Styles.checkLabel}`; // prettier-ignore
const StyledLink = styled.a`${Styles.link}`; // prettier-ignore

const INPUTS_INITIAL = {
  blog: {
    status: 0,
    value: '',
  },
  city: {
    status: 0,
    value: '',
  },
  client: {
    status: 0,
    value: '',
  },
  company: {
    status: 0,
    value: '',
  },
  lastName: {
    status: 0,
    value: '',
  },
  mail: {
    status: 0,
    value: '',
  },
  name: {
    status: 0,
    value: '',
  },
  phone: {
    status: 0,
    value: '',
  },
  privacy: {
    status: 0,
    value: '',
  },
  rfc: {
    status: 0,
    value: '',
  },
};
const CONTACT_TYPE_INITIAL = { status: 0, value: '' };

/**
 * ContactForm component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const ContactFormTech = ({
  description,
  formId,
  isCompact,
  portalId,
  title,
}) => {
  const contactCategories = useContactCategories();
  const nameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const mailRef = useRef();
  const optionsRef = useRef();
  const rfcRef = useRef();
  const companyRef = useRef();
  const cityRef = useRef();
  const cargoRef = useRef();
  const privacidadRef = useRef();
  const blogRef = useRef();
  const clienteAlestraRef = useRef();
  const [contactType, setContactType] = useState(CONTACT_TYPE_INITIAL);
  const [validForm, setValidForm] = useState(false);
  const [sending, setSending] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [inputs, setInputs] = useState(INPUTS_INITIAL);
  const [remoteAddress, setRemoteAddress] = useState(null);
  const [cargo, setCargo] = useState(CONTACT_TYPE_INITIAL);
  const [alestraCliente, setAlestraCliente] = useState(CONTACT_TYPE_INITIAL);
  const [privacidad, setPrivacidad] = useState(false);
  const [blogSub, setBlogSub] = useState(false);

  const handleInputChange = useCallback(event => {
    event.persist();
    const {
      target: { name, type, value }
    } = event;

    setInputs(inputsValues => ({
      ...inputsValues,
      [name]: {
        status: validateInputs(value, type) ? 1 : 2,
        value,
      }
    }));
  }, []);

  const handleContactType = useCallback(event => {
    const {
      target: { value }
    } = event;

    setContactType({
      status: value !== '' ? 1 : 2,
      value,
    });
  }, []);

  const handleCargo = useCallback(event => {
    const {
      target: { value }
    } = event;

    setCargo({
      status: value !== '' ? 1 : 2,
      value,
    });
  }, []);

  const handleClientAlestra = useCallback(event => {
    const {
      target: { value }
    } = event;

    setAlestraCliente({
      status: value !== '' ? 1 : 2,
      value,
    });
  }, []);

  const handlePrivacidad = useCallback(event => {
    const {
      target: { checked, value }
    } = event;

    setPrivacidad({
      status: value !== '' && checked ? 1 : 2,
      value,
    });
  }, []);

  const handleBlogSub = useCallback(event => {
    const {
      target: { checked, value }
    } = event;

    const inputValue = checked ? value : 'No';

    setBlogSub({
      status: checked ? 1 : 2,
      value: inputValue,
    });
  }, []);

  const hasName = getKey(inputs, 'name.status', 0) === 1;
  const hasLastName = getKey(inputs, 'lastName.status', 0) === 1;
  const hasMail = getKey(inputs, 'mail.status', 0) === 1;
  const hasPhone = getKey(inputs, 'phone.status', 0) === 1;
  const hasCargo = getKey(cargo, 'status', 0) === 1;
  const hasCompany = getKey(inputs, 'company.status', 0) === 1;
  const hasRfc = getKey(inputs, 'rfc.status', 0) === 1;
  const clienteAlestraSelected = getKey(alestraCliente, 'status', 0) === 1;
  const hasContactType = getKey(contactType, 'status', 0) === 1;
  const isPrivacySelected = getKey(privacidad, 'status', 0) === 1;

  useEffect(() => {
    if (
      hasName
      && hasLastName
      && hasPhone
      && hasMail
      && hasContactType
      && clienteAlestraSelected
      && hasCargo
      && hasCompany
      && hasRfc
      && isPrivacySelected
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [
    inputs,
    contactType,
    hasName,
    hasLastName,
    hasPhone,
    hasMail,
    hasContactType,
    clienteAlestraSelected,
    hasCargo,
    hasCompany,
    hasRfc,
    isPrivacySelected,
  ]);

  useEffect(() => {
    if (!remoteAddress) {
      const address = localStorage.getItem('remoteAddress');

      if (!address) {
        getRemoteAddress(setRemoteAddress);
      } else {
        setRemoteAddress(address);
      }
    }
  }, [remoteAddress]);

  const focusForm = useCallback(() => {
    if (!hasName) {
      nameRef.current.focus();
    } else if (!hasLastName) {
      lastNameRef.current.focus();
    } else if (!hasPhone) {
      phoneRef.current.focus();
    } else if (!hasMail) {
      mailRef.current.focus();
    } else if (!hasPhone) {
      phoneRef.current.focus();
    } else if (!clienteAlestraSelected) {
      clienteAlestraRef.current.focus();
      setAlestraCliente({
        ...alestraCliente,
        status: 2,
      });
    } else if (!hasCompany) {
      companyRef.current.focus();
    } else if (!hasRfc) {
      rfcRef.current.focus();
    } else if (!hasRfc) {
      rfcRef.current.focus();
    } else if (!isPrivacySelected) {
      privacidadRef.current.focus();
      setPrivacidad({
        ...privacidad,
        status: 2,
      });
    } else if (!hasContactType) {
      optionsRef.current.focus();
      setContactType({
        ...contactType,
        status: 2,
      });
    }
  }, [
    hasName,
    hasLastName,
    hasPhone,
    hasMail,
    hasContactType,
    contactType,
    alestraCliente,
    clienteAlestraSelected,
    hasCompany,
    hasRfc,
    isPrivacySelected,
    privacidad,
  ]);

  const handleSubmit = useCallback(event => {
    if (event) {
      event.preventDefault();

      if (validForm) {
        setSending(true);
      } else {
        focusForm();
      }
    }
  }, [validForm, focusForm]);

  useEffect(() => {
    if (sending) {
      axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
          {
            context: {
              ipAddress: remoteAddress,
              pageName: 'Alestra',
              pageUri: window.location.href,
            },
            fields: [
              {
                name: 'firstname',
                value: inputs.name.value,
              },
              {
                name: 'lastname',
                value: inputs.lastName.value
              },
              {
                name: 'email',
                value: inputs.mail.value
              },
              {
                name: 'phone',
                value: inputs.phone.value
              },
              {
                name: 'ale___servicio_de_interes',
                value: contactType.value
              },
              {
                name: 'razon_social_persona_fisica',
                value: inputs.rfc.value,
              },
              {
                name: 'company',
                value: inputs.company.value,
              },
              {
                name: 'city',
                value: inputs.city.value,
              },
              {
                name: 'phone',
                value: inputs.phone.value,
              },
              {
                name: 'ale___cargo',
                value: cargo.value
              },
              {
                name: 'eres_cliente_alestra_',
                value: alestraCliente.value
              },
              {
                name: 'acepto_el_aviso_de_privacidad',
                value: 'Sí'
              },
              {
                name: 'ale_atim_lifecycle_stage',
                value: 'lead'
              },
              {
                name: 'ale_atim_lead_inbound',
                value: 'Yes'
              },
              {
                name: 'ale_blog_subscription_checkbox',
                value: getKey(blogSub, 'value', 'No'),
              }
            ],
            skipValidation: true,
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then(({ data }) => {
          setSuccessStatus(true);
          setSending(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          setSending(false);
          setSuccessStatus(false);
          setInputs(INPUTS_INITIAL);
          setContactType(CONTACT_TYPE_INITIAL);
          setAlestraCliente(CONTACT_TYPE_INITIAL);
          setCargo(CONTACT_TYPE_INITIAL);
        });
    }
  }, [
    sending,
    portalId,
    formId,
    inputs,
    contactType,
    remoteAddress,
    alestraCliente,
    cargo,
    blogSub,
  ]);

  const inputClassName = isCompact ? 'col-12' : 'col-12 col-md-6';

  return (
    <Wrapper>
      <form className="container" onSubmit={handleSubmit}>
        <ContentWrapper className="row" isCompact={isCompact}>
          <Content
            className={isCompact ? 'col-12' : 'col-12 col-md-4'}
            description
          >
            <Title isCompact={isCompact}>{title}</Title>
            <Description>{description}</Description>
          </Content>
          <Content
            className={isCompact ? 'col-12' : 'col-12 col-md-8'}
            fields
            isCompact={isCompact}
          >
            <div className="row">
              <InputField
                className={inputClassName}
                placeHolder="Nombre"
                onChange={handleInputChange}
                value={inputs.name.value}
                name="name"
                reference={nameRef}
                fixAutofill="transparent"
                status={inputs.name.status}
              />
              <InputField
                className={inputClassName}
                placeHolder="Apellido"
                onChange={handleInputChange}
                value={inputs.lastName.value}
                name="lastName"
                reference={lastNameRef}
                fixAutofill="transparent"
                status={inputs.lastName.status}
              />
              <InputField
                className={inputClassName}
                placeHolder="Correo corporativo"
                onChange={handleInputChange}
                value={inputs.mail.value}
                name="mail"
                type="email"
                reference={mailRef}
                fixAutofill="transparent"
                status={inputs.mail.status}
              />
              <InputField
                className={inputClassName}
                placeHolder="Teléfono"
                onChange={handleInputChange}
                value={inputs.phone.value}
                name="phone"
                type="tel"
                reference={phoneRef}
                fixAutofill="transparent"
                status={inputs.phone.status}
              />
              <Options
                options={CARGO_CATEGORIES}
                value={cargo.value}
                name="cargo"
                onChange={handleCargo}
                reference={cargoRef}
                className="col-12"
                status={cargo.status}
              />
              <Options
                options={CLIENT_CATEGORIES}
                value={alestraCliente.value}
                name="client"
                onChange={handleClientAlestra}
                reference={clienteAlestraRef}
                className="col-12"
                status={alestraCliente.status}
              />
              <InputField
                className="col-12"
                placeHolder="Nombre de la empresa"
                onChange={handleInputChange}
                value={inputs.company.value}
                name="company"
                reference={companyRef}
                fixAutofill="transparent"
                status={inputs.company.status}
                isHidden={!clienteAlestraSelected}
              />
              <InputField
                className="col-12"
                placeHolder="Razon Social"
                onChange={handleInputChange}
                value={inputs.rfc.value}
                name="rfc"
                reference={rfcRef}
                fixAutofill="transparent"
                status={inputs.rfc.status}
                isHidden={!clienteAlestraSelected}
              />
              <InputField
                className="col-12"
                placeHolder="Ciudad"
                onChange={handleInputChange}
                value={inputs.city.value}
                name="city"
                reference={cityRef}
                fixAutofill="transparent"
                status={inputs.city.status}
                isHidden={alestraCliente.value !== 'No'}
              />
              <Options
                options={contactCategories}
                value={contactType.value}
                name="contactType"
                onChange={handleContactType}
                reference={optionsRef}
                className="col-12"
                status={contactType.status}
              />
              <div className="col-12">
                <input type="checkbox" value="Yes" name="privacidad" id="ale_politica_privacidad" onChange={handlePrivacidad} ref={privacidadRef} />
                <CheckLabel hasStatus={privacidad.status !== 2} htmlFor="ale_politica_privacidad">
                  Acepto <StyledLink href="/aviso-de-privacidad">la politica de privacidad</StyledLink>
                </CheckLabel>
              </div>
              <div className="col-12">
                <input type="checkbox" value="Yes" name="blog" id="ale_suscripcion_blog" onChange={handleBlogSub} ref={blogRef} />
                <CheckLabel hasStatus htmlFor="ale_suscripcion_blog">Quiero suscribirme al blog</CheckLabel>
              </div>
              <SubmitMask className="col-12">
                {(sending)
                  ? <Loading />
                  : !successStatus && <Submit type="submit" isActive={validForm}>Enviar</Submit>}
                {successStatus && (
                  <SuccessMessage>¡Muchas gracias!</SuccessMessage>
                )}
              </SubmitMask>
            </div>
          </Content>
        </ContentWrapper>
      </form>
    </Wrapper>
  );
};

ContactFormTech.propTypes = {
  description: PropTypes.string,
  formId: PropTypes.string,
  isCompact: PropTypes.bool,
  portalId: PropTypes.string,
  title: PropTypes.string,
};

ContactFormTech.defaultProps = {
  description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit vehicula, vivamus cum pharetra habitant volutpat habitasse morbi vel hendrerit.',
  title: 'Escríbenos',
};

export default ContactFormTech;
