import { css } from 'styled-components';
import {
  GRAY,
  WHITE,
  ULTRAMARINE
} from '../../../../constants/colors';
import { media, rem } from '../../../../helpers/mixins';

export default {
  icon: ({ isActive }) => css`
    height: 35px;
    margin-right: 15px;
    ${!isActive && css`
      display: none;
    `}
  `,
  line: ({ gradient }) => css`
    background-image: linear-gradient(to left, ${gradient.start} 0%, ${gradient.end} 100%);
    display: none;
    height: 2px;
    position: absolute;
    right: -12px;
    top: 50%;
    width: 12px;

    ${media.md`
      display: block;
    `}
  `,
  styledButton: ({ color, isActive }) => css`
    align-items: center;
    background-color: ${ULTRAMARINE};
    border: 0;
    border-top: 1px solid ${GRAY};
    color: ${isActive ? color : WHITE};
    cursor: pointer;
    display: flex;
    font-size: ${rem(15)};
    height: 64px;
    line-height: ${rem(20)};
    padding-right: 5px;
    position: relative;
    width: 100%;
    ${isActive && css`
      border-bottom: 1px solid ${GRAY};

      &::before {
        background-color: ${color};
        border-radius: 6px;
        content: ' ';
        display: inline-block;
        height: 6px;
        margin-right: 16px;
        width: 6px;
      }
    `}

    ${media.md`
      border-bottom: 0;
      height: 60px;
    `}
  `,
};
