import { graphql } from 'gatsby';

import { isValidArray, getKey } from '../../helpers';
import OptionsCard from '../../components/compound/OptionsCard';

/**
 * entrypointcard widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function entrypointcard (props, widgetType) {
  const links = isValidArray(props.links) && props.links.map(link => ({
    externalPage: getKey(link, 'externalPage'),
    key: getKey(link, 'page.key'),
    label: getKey(link, 'label'),
    title: getKey(link, 'page.title'),
    uri: getKey(link, 'page.uri'),
  }));

  return {
    Component: OptionsCard,
    backgroundImage: getKey(props, 'backgroundImage.childImageSharp.fluid'),
    backgroundImageMobile: getKey(props, 'backgroundImageMobile.childImageSharp.fluid'),
    cardGradient: getKey(props, 'cardGradient'),
    description: getKey(props, 'description'),
    gradient: getKey(props, 'gradient'),
    id: getKey(props, 'key'),
    isVerticalSlide: getKey(props, 'isVerticalSlide'),
    key: getKey(props, 'key'),
    links,
    showSlideTitle: getKey(props, 'showSlideTitle', false),
    textColor: getKey(props, 'textColor', '#ffffff'),
    title: getKey(props, 'title'),
    videoDesktop: getKey(props, 'videoDesktop.uri'),
    videoMobile: getKey(props, 'videoMobile.uri'),
    widgetOrder: getKey(props, 'widgetOrder'),
    widgetType,
  };
}

/**
 * GraphQL Query
 */
export const query = graphql`
  fragment EntrypointCard on StrapiPagesWidgets {
    entrypointcard {
      key: id
      widgetOrder
      title
      isVerticalSlide
      description: content
      showSlideTitle
      textColor
      gradient {
        id
        end
        start
      }
      cardGradient {
        id
        end
        start
      }
      links {
        externalPage
        label
        page {
          key: id
          title
          uri: slug
        }
      }
      videoMobile {
        uri: url
      }
      videoDesktop {
        uri: url
      }
      backgroundImage {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImageMobile {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
