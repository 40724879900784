import React, {
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';

import { validateInputs, getKey, getRemoteAddress } from '../../../helpers';
import useContactCategories from '../../../helpers/hooks/queries/contactCategories';
import InputField from '../../base/InputField';
import OptionsInput from '../../base/OptionsInput';
import Text from '../../base/Text';
import Styles from './ContactForm.styles';

const Content = styled.div`${Styles.content}`; // prettier-ignore
const ContentWrapper = styled.div`${Styles.contentWrapper}`; // prettier-ignore
const Description = styled(Text)`${Styles.description}`; // prettier-ignore
const Loading = styled.div`${Styles.loading}`; // prettier-ignore
const Options = styled(OptionsInput)`${Styles.options}`; // prettier-ignore
const Submit = styled.button`${Styles.submit}`; // prettier-ignore
const SubmitMask = styled.div`${Styles.submitMask}`; // prettier-ignore
const SuccessMessage = styled(Text)`${Styles.successMessage}`; // prettier-ignore
const Title = styled(Text).attrs({ bold: true })`${Styles.title}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

const INPUTS_INITIAL = {
  lastName: {
    status: 0,
    value: '',
  },
  mail: {
    status: 0,
    value: '',
  },
  name: {
    status: 0,
    value: '',
  },
  phone: {
    status: 0,
    value: '',
  },
  rfc: {
    status: 0,
    value: '',
  },
};
const CONTACT_TYPE_INITIAL = { status: 0, value: '' };

/**
 * ContactForm component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const ContactForm = ({
  description,
  formId,
  isCompact,
  portalId,
  title,
}) => {
  const contactCategories = useContactCategories();
  const nameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const mailRef = useRef();
  const optionsRef = useRef();
  const rfcRef = useRef();
  const [contactType, setContactType] = useState(CONTACT_TYPE_INITIAL);
  const [validForm, setValidForm] = useState(false);
  const [sending, setSending] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [inputs, setInputs] = useState(INPUTS_INITIAL);
  const [remoteAddress, setRemoteAddress] = useState(null);

  const handleInputChange = useCallback(event => {
    event.persist();
    const {
      target: { name, type, value }
    } = event;

    setInputs(inputsValues => ({
      ...inputsValues,
      [name]: {
        status: validateInputs(value, type) ? 1 : 2,
        value,
      }
    }));
  }, []);

  const handleContactType = useCallback(event => {
    const {
      target: { value }
    } = event;

    setContactType({
      status: value !== '' ? 1 : 2,
      value,
    });
  }, []);

  const hasName = getKey(inputs, 'name.status', 0) === 1;
  const hasLastName = getKey(inputs, 'lastName.status', 0) === 1;
  const hasPhone = getKey(inputs, 'phone.status', 0) === 1;
  const hasMail = getKey(inputs, 'mail.status', 0) === 1;
  const hasContactType = getKey(contactType, 'status', 0) === 1;

  useEffect(() => {
    if (hasName && hasLastName && hasPhone && hasMail && hasContactType) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [inputs, contactType, hasName, hasLastName, hasPhone, hasMail, hasContactType]);

  useEffect(() => {
    if (!remoteAddress) {
      const address = localStorage.getItem('remoteAddress');

      if (!address) {
        getRemoteAddress(setRemoteAddress);
      } else {
        setRemoteAddress(address);
      }
    }
  }, [remoteAddress]);

  const focusForm = useCallback(() => {
    if (!hasName) {
      nameRef.current.focus();
    } else if (!hasLastName) {
      lastNameRef.current.focus();
    } else if (!hasPhone) {
      phoneRef.current.focus();
    } else if (!hasMail) {
      mailRef.current.focus();
    } else if (!hasContactType) {
      optionsRef.current.focus();
      setContactType({
        ...contactType,
        status: 2,
      });
    }
  }, [hasName, hasLastName, hasPhone, hasMail, hasContactType, contactType]);

  const handleSubmit = useCallback(event => {
    if (event) {
      event.preventDefault();

      if (validForm) {
        setSending(true);
      } else {
        focusForm();
      }
    }
  }, [validForm, focusForm]);

  useEffect(() => {
    if (sending) {
      axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
          {
            fields: [
              {
                name: 'firstname',
                value: inputs.name.value,
              },
              {
                name: 'lastname',
                value: inputs.lastName.value
              },
              {
                name: 'email',
                value: inputs.mail.value
              },
              {
                name: 'mobilephone',
                value: inputs.phone.value
              },
              {
                name: 'interesado_en',
                value: contactType.value
              },
              {
                name: 'rfc',
                value: inputs.rfc.value,
              },
              {
                name: 'ip',
                value: remoteAddress,
              }
            ],
            skipValidation: true,
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then(({ data }) => {
          setSuccessStatus(true);
          setSending(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          setSending(false);
          setSuccessStatus(false);
          setInputs(INPUTS_INITIAL);
          setContactType(CONTACT_TYPE_INITIAL);
        });
    }
  }, [sending, portalId, formId, inputs, contactType, remoteAddress]);

  const inputClassName = isCompact ? 'col-12' : 'col-12 col-md-6';

  return (
    <Wrapper>
      <form className="container" onSubmit={handleSubmit}>
        <ContentWrapper className="row" isCompact={isCompact}>
          <Content
            className={isCompact ? 'col-12' : 'col-12 col-md-4'}
            description
          >
            <Title isCompact={isCompact}>{title}</Title>
            <Description>{description}</Description>
          </Content>
          <Content
            className={isCompact ? 'col-12' : 'col-12 col-md-8'}
            fields
            isCompact={isCompact}
          >
            <div className="row">
              <InputField
                className={inputClassName}
                placeHolder="Nombre"
                onChange={handleInputChange}
                value={inputs.name.value}
                name="name"
                reference={nameRef}
                fixAutofill="transparent"
                status={inputs.name.status}
              />
              <InputField
                className={inputClassName}
                placeHolder="Apellido"
                onChange={handleInputChange}
                value={inputs.lastName.value}
                name="lastName"
                reference={lastNameRef}
                fixAutofill="transparent"
                status={inputs.lastName.status}
              />
              <InputField
                className={inputClassName}
                placeHolder="Teléfono"
                onChange={handleInputChange}
                value={inputs.phone.value}
                name="phone"
                type="tel"
                reference={phoneRef}
                fixAutofill="transparent"
                status={inputs.phone.status}
              />
              <InputField
                className={inputClassName}
                placeHolder="Correo corporativo"
                onChange={handleInputChange}
                value={inputs.mail.value}
                name="mail"
                type="email"
                reference={mailRef}
                fixAutofill="transparent"
                status={inputs.mail.status}
              />
              <InputField
                className="col-12"
                placeHolder="RFC (Opcional)"
                onChange={handleInputChange}
                value={inputs.rfc.value}
                name="rfc"
                reference={rfcRef}
                fixAutofill="transparent"
                status={inputs.rfc.status}
              />
              <Options
                options={contactCategories}
                value={contactType.value}
                name="contactType"
                onChange={handleContactType}
                reference={optionsRef}
                className="col-12"
                status={contactType.status}
              />
              <SubmitMask className="col-12">
                {(sending)
                  ? <Loading />
                  : !successStatus && <Submit type="submit" isActive={validForm}>Enviar</Submit>}
                {successStatus && (
                  <SuccessMessage>¡Muchas gracias!</SuccessMessage>
                )}
              </SubmitMask>
            </div>
          </Content>
        </ContentWrapper>
      </form>
    </Wrapper>
  );
};

ContactForm.propTypes = {
  description: PropTypes.string,
  formId: PropTypes.string,
  isCompact: PropTypes.bool,
  portalId: PropTypes.string,
  title: PropTypes.string,
};

ContactForm.defaultProps = {
  description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit vehicula, vivamus cum pharetra habitant volutpat habitasse morbi vel hendrerit.',
  title: 'Escríbenos',
};

export default ContactForm;
