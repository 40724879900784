import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { useIsInView, useInterval } from '../../../helpers/hooks';
import { setActiveSlide } from '../../../store/actions';
import Text from '../../base/Text';
import Video from '../../base/Video';
import ImageFluidWrapper from '../../base/ImageFluidWrapper';
import ImageFluid from '../../base/ImageFluid';
import Styles from './HeaderCard.styles';

const ContentWrapper = styled.div`${Styles.contentWrapper}`; // prettier-ignore
const Contents = styled(Text).attrs({ bold: true })`${Styles.contents}`; // prettier-ignore
const MainContent = styled.div`${Styles.mainContent}`; // prettier-ignore
const PlaceHolderDesktop = styled(ImageFluid)`${Styles.placeHolderDesktop}`; // prettier-ignore
const PlaceHolderMobile = styled(ImageFluid)`${Styles.placeHolderMobile}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * HeaderCard component
 * @param {Object} props - component props
 * @param {Object} props.contents - component bullets
 * @param {Object} props.duration - animation duration
 * @param {Object} props.index - component index in the slides
 * @param {Object} props.videoDesktop - desktop asset
 * @param {Object} props.videoMobile - mobile asset
 * @returns {JSx}
 */
const HeaderCard = ({
  backgroundImage, backgroundImageMobile, contents, duration, index, videoDesktop, videoMobile,
}) => {
  const [currentContent, setCurrentContent] = useState(0);
  const [shouldPlay, setShouldPlay] = useState(false);
  const message = contents[currentContent].content;
  const dispatch = useDispatch();
  const [ref, isIntersecting] = useIsInView();

  if (isIntersecting) {
    if (!shouldPlay) {
      setShouldPlay(true);
    }
    dispatch(setActiveSlide(index));
  } else if (shouldPlay && !isIntersecting) {
    setShouldPlay(false);
  }

  useInterval(
    () => {
      const newValue = currentContent + 1 >= contents.length ? 0 : currentContent +  1;
      setCurrentContent(newValue);
    },
    isIntersecting ? duration * 1000 : null
  );

  return (
    <Wrapper ref={ref}>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MainContent>
                <Contents>
                  {message}
                </Contents>
              </MainContent>
            </div>
          </div>
        </div>
      </ContentWrapper>
      <ImageFluidWrapper>
        <PlaceHolderDesktop fluid={backgroundImage} />
        <PlaceHolderMobile fluid={backgroundImageMobile} />
      </ImageFluidWrapper>
      <Video
        videoDesktop={videoDesktop}
        videoMobile={videoMobile}
        shouldPlay={shouldPlay}
      />
    </Wrapper>
  );
};

HeaderCard.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  contents: PropTypes.array,
  duration: PropTypes.number,
  index: PropTypes.number,
  videoDesktop: PropTypes.string,
  videoMobile: PropTypes.string,
};

export default HeaderCard;
