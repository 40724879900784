import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { batch, useDispatch } from 'react-redux';

import { setHasSlider, scrollToTop } from '../store/actions';

import { isValidArray } from '../helpers';
import mapWidgetProps from './mapWidgetProps';
import ScrollSlider from '../components/compound/ScrollSlider';

/**
 * WidgetFactory
 * @param {Object} props - component props
 * @returns {JSX}
 */
const WidgetFactory = props => {
  const { location, widgets } = props;
  const dispatch = useDispatch();

  const widgetList = Object.keys(widgets)
    .filter(widget => isValidArray(widgets[widget]))
    .reduce(
      (acc, widget) => [
        ...acc,
        ...widgets[widget].map(widgetProps => mapWidgetProps(widget, widgetProps))], []
    )
    // remove unmapped widgets
    .filter(widget => widget)
    // sort widgets by position
    .sort((a, b) => a.widgetOrder - b.widgetOrder);

  const hasSlider = widgetList.some(widget => widget.isVerticalSlide);

  batch(() => {
    dispatch(setHasSlider(hasSlider));
    dispatch(scrollToTop());
  });

  const renderWidgets = widgetList.map(widget => {
    const { Component, ...componentProps } = widget;

    return (
      <Component {...componentProps} location={location} />
    );
  });

  if (!isValidArray(renderWidgets)) {
    return (
      <div style={{ color: 'white ' }}>
        <div className="container">
          <div className="col-12" style={{ height: '100vh', paddingTop: 100 }}>
            Lo sentimos, la pagina que buscas no está disponible.
          </div>
        </div>
      </div>
    );
  }

  if (hasSlider) {
    return (
      <ScrollSlider>
        {renderWidgets}
      </ScrollSlider>
    );
  }

  return renderWidgets;
};

export default WidgetFactory;

WidgetFactory.propTypes = {
  location: PropTypes.object,
  widgets: PropTypes.object,
};

export const query = graphql`
  fragment Widgets on StrapiPagesWidgets {
    ...ActionCard
    ...ActivityCard
    ...EntrypointCard
    ...HeaderCard
    ...SolutionsCard
    ...ContentCard
    ...ContactForm
    ...ContactFormExtended
    ...InternalNavigation
  }
`;
