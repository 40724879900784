import { css } from 'styled-components';
import { WOODSMOKE } from '../../../constants/colors';
import { media } from '../../../helpers/mixins';

export default {
  placeHolderDesktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  placeHolderMobile: css`
    ${media.md`
      display: none;
    `}
  `,
  wrapper: ({ backgroundImage }) => css`
    background-color: ${WOODSMOKE};
    height: 0;
    overflow: hidden;
    padding-bottom: 52.5%;
    position: relative;
    width: 100%;

    ${media.md`
      padding-bottom: 23%;
    `}

    ${backgroundImage && css`
      background-image: url(${backgroundImage});
      background-position: center;
      background-size: cover;
    `}
  `,
};
