import { css } from 'styled-components';
import { media } from '../../../../helpers/mixins';

export default {
  itemContainer: css`
    margin-left: calc(3/12 * 100%);

    ${media.md`
      margin-left: 0;
    `}
  `,
  line: ({ gradient }) => css`
    background-image: linear-gradient(to right, ${gradient.start} 0%, ${gradient.end} 100%);
    display: none;
    height: 2px;
    left: -10px;
    position: absolute;
    top: 31px;
    width: 12px;

    ${media.md`
      display: block;
    `}
  `,
  wrapper: ({ isActive }) => css`
    display: ${isActive ? 'block' : 'none'};
    padding-bottom: 10px;
    padding-top: 20px;
  `,
};
