import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';

import { setActiveSlide } from '../../../store/actions';
import { useIsInView } from '../../../helpers/hooks';
import ImageFluidWrapper from '../../base/ImageFluidWrapper';
import ImageFluid from '../../base/ImageFluid';
import Text from '../../base/Text';
import Link from '../../base/Link';
import Video from '../../base/Video';
import Styles from './OptionsCard.styles';
import { getKey, isAbsoluteUrl, isValidArray } from '../../../helpers';

const CallToAction = styled(
  ({
    idName,
    isLast,
    ...otherProps
  }) => <Link {...otherProps} />
)`${Styles.callToAction}`; // prettier-ignore
const CallToActionWrapper = styled.div`${Styles.callToActionWrapper}`; // prettier-ignore
const ContentWrapper = styled.div`${Styles.contentWrapper}`; // prettier-ignore
const Description = styled(Text)`${Styles.description}`; // prettier-ignore
const MainContent = styled.div`${Styles.mainContent}`; // prettier-ignore
const PlaceHolderDesktop = styled(ImageFluid)`${Styles.placeHolderDesktop}`; // prettier-ignore
const PlaceHolderMobile = styled(ImageFluid)`${Styles.placeHolderMobile}`; // prettier-ignore
const SvgRect = styled.rect`${Styles.svgRect}`; // prettier-ignore
const SvgWrapper = styled.svg`${Styles.svgWrapper}`; // prettier-ignore
const Title = styled(Text)`${Styles.title}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * OptionsCard component
 * @param {Object} props - component props
 * @param {boolean} props.description - component description
 * @returns {JSX}
 */
const OptionsCard = ({
  backgroundImage,
  backgroundImageMobile,
  description,
  gradient,
  index,
  links,
  textColor,
  title,
  videoDesktop,
  videoMobile,
}) => {
  const dispatch = useDispatch();
  const [ref, isIntersecting] = useIsInView();
  const [shouldPlay, setShouldPlay] = useState(false);
  const callToActions = useMemo(() => {
    if (!isValidArray(links)) return null;

    const linksLenght = links.length - 1;

    return links.map((link, idx) => {
      const linkTitle = getKey(link, 'label', getKey(link, 'title'));
      const gradientId = getKey(gradient, 'id');
      const isLast = idx === linksLenght && idx % 2 === 0;
      const uri = getKey(link, 'externalPage', getKey(link, 'uri'));

      return (
        <CallToAction href={uri} idName={gradientId} isLast={isLast} key={`cat${uri}`} preloadDisabled={isAbsoluteUrl(uri)}>
          {linkTitle}
          <SvgWrapper>
            <defs>
              <linearGradient id={gradientId}>
                <stop offset="0" style={{ stopColor: getKey(gradient, 'start') }} />
                <stop offset="1" style={{ stopColor: getKey(gradient, 'end') }} />
              </linearGradient>
            </defs>
            <SvgRect x="0.5" y="0.5" rx="18" ry="18" width="100%" height="100%" idName={gradientId} />
          </SvgWrapper>
        </CallToAction>
      );
    });
  }, [links, gradient]);

  if (isIntersecting) {
    if (!shouldPlay) {
      setShouldPlay(true);
    }
    dispatch(setActiveSlide(index));
  } else if (shouldPlay && !isIntersecting) {
    setShouldPlay(false);
  }

  return (
    <Wrapper ref={ref}>
      <ThemeProvider theme={{ textColor }}>
        <ContentWrapper>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <MainContent>
                  <Title bold>
                    {title}
                  </Title>
                  <Description>
                    {description}
                  </Description>
                  {callToActions && (
                    <CallToActionWrapper>
                      {callToActions}
                    </CallToActionWrapper>
                  )}
                </MainContent>
              </div>
            </div>
          </div>
        </ContentWrapper>
        <ImageFluidWrapper>
          <PlaceHolderDesktop fluid={backgroundImage} />
          <PlaceHolderMobile fluid={backgroundImageMobile} />
        </ImageFluidWrapper>
        <Video
          videoDesktop={videoDesktop}
          videoMobile={videoMobile}
          shouldPlay={shouldPlay}
        />
      </ThemeProvider>
    </Wrapper>
  );
};

OptionsCard.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  description: PropTypes.string,
  gradient: PropTypes.object,
  index: PropTypes.number,
  links: PropTypes.array,
  textColor: PropTypes.string,
  title: PropTypes.string,
  videoDesktop: PropTypes.string,
  videoMobile: PropTypes.string,
};

export default React.memo(OptionsCard);
