import { graphql } from 'gatsby';

import TypeA from '../../components/compound/ActionCard/TypeA';
import TypeB from '../../components/compound/ActionCard/TypeB';
import { isValidArray, getKey } from '../../helpers';

/**
 * actioncard widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function actioncard(props, widgetType) {
  const links = isValidArray(props.callToAction)
    ? props.callToAction.map(link => ({
      externalPage: getKey(link, 'externalPage'),
      key: getKey(link, 'page.key', 'key'),
      label: getKey(link, 'label'),
      title: getKey(link, 'page.title'),
      uri: getKey(link, 'page.uri'),
    }))
    : [];

  const flavor = getKey(props, 'flavor', 'A');
  const cardGradient = {
    end: getKey(props, 'callToActionHighlightColor', '#f0f'),
    id: getKey(props, 'key'),
    start: getKey(props, 'callToActionHighlightColor', '#f0f'),
  };

  return {
    Component: flavor === 'A' ? TypeA : TypeB,
    backgroundColor: getKey(props, 'backgroundColor'),
    backgroundImage: getKey(props, 'backgroundImage.childImageSharp.fluid'),
    backgroundImageMobile: getKey(props, 'backgroundImageMobile.childImageSharp.fluid'),
    callToActionHighlightColor: getKey(props, 'callToActionHighlightColor', '#f0f'),
    cardGradient,
    description: getKey(props, 'description'),
    flavor: getKey(props, 'flavor', 'A'),
    id: getKey(props, 'key'),
    isVerticalSlide: getKey(props, 'isVerticalSlide'),
    key: getKey(props, 'key'),
    links,
    showSlideTitle: getKey(props, 'showSlideTitle', false),
    subtitle: getKey(props, 'subtitle'),
    subtitleColor: getKey(props, 'subtitleColor'),
    textAlignment: getKey(props, 'textAlignment', 'center'),
    title: getKey(props, 'title'),
    widgetOrder: getKey(props, 'widgetOrder'),
    widgetType,
  };
}

export const query = graphql`
  fragment ActionCard on StrapiPagesWidgets {
    actioncard {
      key: id
      backgroundColor
      callToAction {
        externalPage
        key: id
        label
        page {
          key: id
          title
          uri: slug
        }
      }
      callToActionHighlightColor
      description
      descriptionColor
      flavor: Flavor
      isVerticalSlide
      label
      showSlideTitle
      subtitle
      subtitleColor
      title
      titleColor
      widgetOrder
      backgroundImage {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImageMobile {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
