import { css } from 'styled-components';
import { rem } from '../../../helpers/mixins';
import { CERULEAN } from '../../../constants/colors';

export default {
  link: css`
    font-size: ${rem(12)};
    line-height: ${rem(16)};
    text-transform: uppercase;
  `,
  linkList: css`
    list-style: none;
    margin: 0;
    padding: 0;
  `,
  linkListItem: ({ isActive }) => css`
    margin-bottom: 10px;

    ${isActive && css`
      font-weight: 700;
    `}
  `,
  title: css`
    color: ${CERULEAN};
    font-size: ${rem(21)};
    line-height: ${rem(29)};
    margin-top: 60px;
    text-transform: uppercase;
  `,
};
