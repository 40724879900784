import { css } from 'styled-components';

import { rem, media } from '../../../helpers/mixins';
import { WHITE } from '../../../constants/colors';

export default {
  callToAction: ({ idName, isLast }) => css`
    display: inline-block;
    font-size: ${rem('12px')};
    font-weight: bold;
    margin: 0 8px 10px;
    padding: 9px 0px 7px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: calc(50% - 16px);
    z-index: 2;

    &:hover svg rect {
      fill: url(#${idName});
    }

    ${isLast && css`
      margin: 0 8px;
      width: calc(100% - 16px);
    `}

    ${media.md`
      font-size: ${rem('14px')};
      margin-left: 5px;
      margin-right: 5px;
      padding: 7px 20px 6px;
      width: auto;
    `}
  `,
  callToActionWrapper: ({ theme }) => css`
    color: ${theme.textColor || WHITE};
    margin-top: 53px;
    padding: 0 8px;
    position: relative;
    width: calc(100% + 48px);

    ${media.md`
      margin-top: 42px;
      padding: 0;
      width: auto;
    `}
  `,
  contentWrapper: css`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  `,
  description: ({ theme }) => css`
    color: ${theme.textColor || WHITE};
    font-size: ${rem('16px')};
    line-height: ${rem('21px')};
    margin-top: 15px;
    text-align: center;

    ${media.md`
      margin-top: 2px;
      max-width: 53%;
      line-height: ${rem('19px')};
    `}
  `,
  mainContent: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
  `,
  placeHolderDesktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  placeHolderMobile: css`
    display: block;

    ${media.md`
      display: none;
    `}
  `,
  svgRect: ({ idName }) => css`
    fill: transparent;
    height: calc(100% - 1px);
    stroke: url(#${idName});
    stroke-width: 1;
    width: calc(100% - 1px);
  `,
  svgWrapper: css`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  `,
  title: ({ theme }) => css`
    color: ${theme.textColor || WHITE};
    font-size: ${rem('24px')};
    line-height: ${rem('30px')};

    ${media.md`
      font-size: ${rem('56px')};
      line-height: ${rem('76px')};
      max-width: 80%;
    `}
  `,
  wrapper: css`
    height: 100vh;
    position: relative;
    width: 100vw;
  `
};
