import { css } from 'styled-components';

import { rem, media } from '../../../helpers/mixins';
import { WHITE, BLACK } from '../../../constants/colors';

export default {
  contentWrapper: css`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  `,
  contents: css`
    color: ${WHITE};
    font-size: ${rem('24px')};
    line-height: ${rem('30px')};

    ${media.md`
      width: 74%;
      font-size: ${rem('56px')};
      line-height: ${rem('58px')};
    `}
  `,
  mainContent: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
  `,
  placeHolderDesktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  placeHolderMobile: css`
    display: block;

    ${media.md`
      display: none;
    `}
  `,
  wrapper: css`
    background-color: ${BLACK};
    height: 100vh;
    position: relative;
    width: 100vw;
  `
};
