import { css } from 'styled-components';

import { media } from '../../../helpers/mixins';

export default {
  slider: ({ shouldHide }) => css`

    ${shouldHide && css`
      min-height: unset !important;
      position: relative;
      z-index: 4;
    `}

    ${media.md`
      min-height: 100vh;
      > div {
        display: flex;
        scroll-snap-align: start;
        scroll-snap-stop: normal;
      }
    `}
  `,
  wrapper: css`
    position: relative;
  `,
};
