import { css, keyframes } from 'styled-components';

import { rem, media } from '../../../helpers/mixins';
import { DODGER_BLUE, WHITE, WHITE_40 } from '../../../constants/colors';

const rotateKeyframe = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export default {
  content: ({ description, fields }) => css`
    ${description && media.md`
      &&& {
        padding-right: 40px;
      }
    `}

    ${fields && css`
      &&& {
        margin-top: 40px;

        ${media.md`
          margin-top: 0;
          padding-left: 50px;
          padding-top: 10px;
        `}
      }
    `}
  `,
  contentWrapper: css`
    padding-bottom: 36px;
    padding-top: 36px;

    ${media.md`
      padding-bottom: 120px;
      padding-top: 120px;
    `}
  `,
  description: css`
    color: ${WHITE};
    font-size: ${rem('16px')};
    line-height: ${rem('21px')};
    margin-top: 12px;

    ${media.md`
      margin-top: 40px;
    `}
  `,
  loading: css`
    animation: ${rotateKeyframe} 2s linear infinite;
    border: 2px solid ${WHITE_40};
    border-radius: 50%;
    border-top: 2px solid ${WHITE};
    height: 32px;
    width: 32px;
  `,
  options: css`
    &&& {
      margin-bottom: 20px;

      ${media.md`
        padding-left: 5px;
        padding-right: 15px;
      `}
    }
  `,
  submit: ({ isActive }) => css`
    background-color: transparent;
    border: 1px solid ${isActive ? WHITE : WHITE_40};
    border-radius: 32px;
    color: ${WHITE};
    font-size: ${rem('12px')};
    font-weight: 700;
    line-height: ${rem('32px')};
    padding: 0 27px;
    text-transform: uppercase;
  `,
  submitMask: css`
    &&& {
      height: 32px;
      position: relative;
      width: 100%;

      ${media.md`
        padding-left: 5px;
        padding-right: 15px;
      `}
    }
  `,
  successMessage: css`
    color: ${WHITE};
  `,
  title: css`
    color: ${WHITE};
    font-size: ${rem('24px')};
    line-height: ${rem('24px')};
    text-transform: capitalize;

    ${media.md`
      font-size: ${rem('42px')};
      line-height: ${rem('42px')};
    `}
  `,
  wrapper: css`
    background: ${DODGER_BLUE};
  `,
};
