import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Remarkable } from 'remarkable';

import { scrollToTop } from '../../../store/actions';
import Banner from '../../base/Banner';
import Styles from './StaticPage.styles';
import Text from '../../base/Text';
import { isValidArray } from '../../../helpers';
import mapWidgetProps from '../../../factories/mapWidgetProps';

const Container = styled.div`${Styles.container}`;
const Title = styled(Text)`${Styles.title}`;
const Content = styled.div`${Styles.content}`;
const MainContent = styled.div`${Styles.mainContent}`;

/**
 * Static Page component
 * @returns {JSX}
 */
const StaticPage = ({
  backgroundImage,
  backgroundImageMobile,
  content,
  location,
  title,
  widgets,
}) => {
  const dispatch = useDispatch();
  const md = new Remarkable({
    breaks: true,
    html: true,
  });
  const renderContent = useMemo(() => {
    if (!content) return null;
    return (
      <Content dangerouslySetInnerHTML={{ __html: md.render(content) }} />
    );
  }, [content, md]);

  const widgetList = Object.keys(widgets)
    .filter(widget => isValidArray(widgets[widget]))
    .reduce(
      (acc, widget) => [
        ...acc,
        ...widgets[widget].map(widgetProps => mapWidgetProps(widget, widgetProps))], []
    )
    // remove unmapped widgets
    .filter(widget => widget)
    // sort widgets by position
    .sort((a, b) => a.widgetOrder - b.widgetOrder);

  const hasWidgets = isValidArray(widgetList);
  const renderWidgets = widgetList.map(widget => {
    const { Component, ...componentProps } = widget;

    return (
      <Component {...componentProps} location={location} />
    );
  });
  const mainContentClassName = hasWidgets ? 'col-12 col-md-9' : 'col-12';

  dispatch(scrollToTop());

  return (
    <>
      <Banner
        backgroundImage={backgroundImage}
        backgroundImageMobile={backgroundImageMobile}
      />
      <Container>
        <div className="container">
          <div className="row">
            {hasWidgets && (
              <div className="col-12 col-md-3">
                {renderWidgets}
              </div>
            )}
            <MainContent className={mainContentClassName}>
              <Title tag="h3">{title}</Title>
              {renderContent}
            </MainContent>
          </div>
        </div>
      </Container>
    </>
  );
};

StaticPage.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  content: PropTypes.string,
  hasContactForm: PropTypes.bool,
  location: PropTypes.object,
  title: PropTypes.string,
  widgets: PropTypes.object,
};

export default StaticPage;
