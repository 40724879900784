import { css } from 'styled-components';
import { WHITE, CERULEAN } from '../../../../constants/colors';
import { media, rem } from '../../../../helpers/mixins';

export default {
  arrowWrapper: css`
    height: 30px;
    left: 50%;
    position: absolute;
    top: -20px;
    transform: translateX(-50%);
    width: 4052px;
  `,
  callToAction: ({ isLast, theme }) => css`
    border: 1px solid ${theme.callToActionHighlightColor || CERULEAN};
    border-radius: 26px;
    color: ${theme.descriptionColor || WHITE};
    display: inline-block;
    font-size: ${rem(12)};
    font-weight: 500;
    margin: 0 8px 10px;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    width: ${isLast ? 'calc(100% - 16px)' : 'calc(50% - 16px)'};
    z-index: 2;

    ${media.md`
      font-size: ${rem(14)};
      width: auto;
    `}

    &:hover,
    &:active {
      background-color: ${theme.callToActionHighlightColor || CERULEAN};
      color: ${theme.descriptionColor || WHITE};
    }
  `,
  callToActionWrapper: css`
    margin-top: 40px;
    width: 100%;

    ${media.md`
      margin-top: 40px;
      max-width: 520px;
      width: auto;
    `}
  `,
  content: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  contentWrapper: ({ theme }) => css`
    align-items: center;
    background-color: ${theme.backgroundColor || CERULEAN};
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 50px 0;
    position: relative;
  `,
  description: ({ theme }) => css`
    color: ${theme.descriptionColor || WHITE};
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    margin: 0 auto 20px;
    max-width: 90%;
    text-align: ${theme.textAlignment || 'center'};

    ${media.md`
      max-width: 55%;
    `}
  `,
  imageWrapper: css`
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    width: 100%;

    ${media.md`
      padding-bottom: 0;
      flex: 1.75;
    `}
  `,
  placeHolderDesktop: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  placeHolderMobile: css`
    ${media.md`
      display: none;
    `}
  `,
  title: ({ theme }) => css`
    color: ${theme.titleColor || WHITE};
    font-size: ${rem(24)};
    font-weight: bold;
    margin: 0 auto 20px;
    max-width: 90%;
    text-align: ${theme.textAlignment || 'center'};

    ${media.md`
      font-size: ${rem(56)};
      max-width: 80%;
    `}
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;

    ${media.md`
      height: 100vh;
    `}
  `,
};
