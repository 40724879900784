import { css } from 'styled-components';
import { rem, horizontalGradient } from '../../../../helpers/mixins';
import {
  GRADIENT_CERULEAN_TO_PURPLE,
  SILVER,
  WHITE,
} from '../../../../constants/colors';

export default {
  container: css`
    margin-bottom: 20px;
  `,
  itemDesc: css`
    color: ${SILVER};
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  `,
  linkComponent: ({ color, gradient }) => css`
    color: ${color || WHITE};
    display: block;
    font-size: ${rem(15)};
    font-weight: 700;
    line-height: ${rem(20)};
    margin-bottom: 10px;
    padding-bottom: 14px;
    position: relative;

    &::after {
      bottom: 0;
      background: ${gradient ? horizontalGradient(gradient.start, gradient.end) : GRADIENT_CERULEAN_TO_PURPLE};
      content: ' ';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 22px;
      transition: width 0.2s;
    }

    &:hover,
    &:active,
    &:focus {
      color: ${SILVER};

      &::after {
        width: 100%;
      }
    }
  `,
};
