import React from 'react';
import PropTypes from 'prop-types';

import ContactForm from '../ContactForm';
import ContactFormExtended from '../ContactFormExtended';
import ContactClientsForm from '../ContactClientsForm';
import ContactFormTech from '../ContactFormTech';
import ContactWebmasterForm from '../ContactWebmasterForm';

const list = {
  clients: ContactClientsForm,
  information: ContactForm,
  information_extended: ContactFormExtended,
  tech: ContactFormTech,
  webmaster: ContactWebmasterForm,
};

/**
 * FormWrapper
 * @param {Object} props - component props
 * @returns {JSX}
 */
const FormWrapper = props => {
  const { formType = 'information' } = props;
  const Component = list[formType] || ContactForm;

  return <Component {...props} />;
};

FormWrapper.propTypes = {
  formType: PropTypes.oneOf(['clients', 'information', 'information_extended', 'tech', 'webmaster']),
};

export default FormWrapper;
