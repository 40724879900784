import { css } from 'styled-components';
import {
  WHITE,
  ULTRAMARINE
} from '../../../constants/colors';
import { media, rem } from '../../../helpers/mixins';

export default {
  container: css`
    background-color: ${ULTRAMARINE};
    color: ${WHITE};
    padding: 30px 0;
    position: relative;

    ${media.md`
      padding: 70px 0;
    `}
  `,
  desktopMask: css`
    display: none;

    ${media.md`
      display: block;
    `}
  `,
  lineConnector: ({
    color = WHITE,
    top = 212,
    left = 0,
    height = 2,
  }) => css`
    background-color: ${color};
    height: ${`${height}px`};
    left: ${`${left}px`};
    position: absolute;
    top: ${`${top}px`};
    width: 2px;
  `,
  mainTitle: css`
    font-size: ${rem(22)};
    margin-bottom: 38px;

    ${media.md`
      font-size: ${rem(42)};
      margin-bottom: 48px;
    `}
  `,
  mobileMask: css`
    display: block;
    ${media.md`
      display: none;
    `}
  `,
};
