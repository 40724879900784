import { graphql } from 'gatsby';

import { getKey, isValidArray, isValidObject } from '../../helpers';
import SolutionsCard from '../../components/compound/SolutionsCard';

/**
 * Groups and extracts form options
 * @param {array} contents - widget contents
 * @returns {Array}
 */
function getFormOptions(contents) {
  if (!isValidArray(contents)) {
    return [];
  }

  return contents.map(content => {
    const { name } = content;
    const options = getKey(content, 'formoptions.0.options', []);

    if (!isValidArray(options)) {
      return null;
    }

    return {
      label: name,
      options: options.map(item => ({
        label: item.label,
        value: item.label,
      })),
    };
  }).filter(item => isValidObject(item));
}

/**
 * solutionsmodule widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function solutionsmodule(props, widgetType) {
  return {
    Component: SolutionsCard,
    contents: getKey(props, 'contents', []).map(c => ({
      color: getKey(c, 'color'),
      gradient: {
        end: getKey(c, 'solutionfamily.gradient.end'),
        start: getKey(c, 'solutionfamily.gradient.start'),
      },
      id: getKey(c, 'id'),
      name: getKey(c, 'name'),
      solutions: getKey(c, 'solutions', []).map(s => ({
        abstract: getKey(s, 'abstract'),
        id: getKey(s, 'id'),
        name: getKey(s, 'name'),
        slug: getKey(s, 'slug')
      })),
    })),
    formId: getKey(props, 'formId'),
    formInputValue: getKey(props, 'formInputValue'),
    formLabel: getKey(props, 'formLabel'),
    formOptions: getFormOptions(getKey(props, 'contents')),
    formPortalId: getKey(props, 'formPortalId'),
    key: getKey(props, 'key'),
    title: getKey(props, 'title'),
    widgetOrder: getKey(props, 'widgetOrder'),
    widgetType,
  };
}

export const query = graphql`
  fragment SolutionsCard on StrapiPagesWidgets {
    solutionscard {
      key: id
      contents {
        color
        id
        name
        solutions {
          abstract
          id
          name
          slug
        }
        solutionfamily {
          gradient {
            start
            end
          }
        }
      }
      formLabel
      formId
      formInputValue
      formPortalId
      title
      widgetOrder
    }
  }
`;
