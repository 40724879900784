import { graphql } from 'gatsby';

import { getKey } from '../../helpers';
import HeaderCard from '../../components/compound/HeaderCard';

/**
 * entrypointcard widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function entrypointcard (props, widgetType) {
  return {
    Component: HeaderCard,
    backgroundImage: getKey(props, 'backgroundImage.childImageSharp.fluid'),
    backgroundImageMobile: getKey(props, 'backgroundImageMobile.childImageSharp.fluid'),
    cardGradient: getKey(props, 'cardGradient'),
    contents: getKey(props, 'contents', []),
    duration: getKey(props, 'duration', 3),
    id: getKey(props, 'key'),
    isVerticalSlide: getKey(props, 'isVerticalSlide'),
    key: getKey(props, 'key'),
    showSlideTitle: getKey(props, 'showSlideTitle', false),
    videoDesktop: getKey(props, 'videoDesktop.uri'),
    videoMobile: getKey(props, 'videoMobile.uri'),
    widgetOrder: getKey(props, 'widgetOrder'),
    widgetType,
  };
}

/**
 * GraphQL Query
 */
export const query = graphql`
  fragment HeaderCard on StrapiPagesWidgets {
    headercard {
      key: id
      cardGradient {
        id
        end
        start
      }
      duration
      contents {
        id
        content
      }
      videoMobile {
        uri: url
      }
      videoDesktop {
        uri: url
      }
      widgetOrder
      verticalSlideOrder
      isVerticalSlide
      backgroundImage {
        childImageSharp {
          fluid(maxWidth: 400, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImageMobile {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
