import { graphql } from 'gatsby';

import { getKey } from '../../helpers';
import ContactFormExtended from '../../components/compound/ContactFormExtended';

/**
 * contactformextended widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function contactformextended (props, widgetType) {
  return {
    Component: ContactFormExtended,
    description: getKey(props, 'description'),
    formId: getKey(props, 'formId'),
    formName: getKey(props, 'name'),
    id: getKey(props, 'key'),
    key: getKey(props, 'key'),
    portalId: getKey(props, 'portalId'),
    title: getKey(props, 'title'),
    widgetType,
  };
}

/**
 * GraphQL Query
 */
export const query = graphql`
  fragment ContactFormExtended on StrapiPagesWidgets {
    contactformextended {
      description
      key: id
      name
      portalId
      formId
      title
    }
  }
`;
