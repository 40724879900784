import { css } from 'styled-components';
import {
  WHITE,
  MID_GRAY,
  CERULEAN,
  GRAY,
} from '../../../constants/colors';
import { media, rem } from '../../../helpers/mixins';

export default {
  container: css`
    background: ${WHITE};
    min-height: 100vh;
  `,
  content: css`
    color: ${MID_GRAY};
    font-size: ${rem(15)};
    line-height: ${rem(24)};

    ${media.md`
      font-size: ${rem(17)};
      line-height: ${rem(25)};
    `}

    h2, h3, h4 {
      color: ${CERULEAN};
      font-weight: 700;
      margin-bottom: 20px;

      ${media.md`
        margin-bottom: 30px;
      `}
    }

    h3 {
      color: ${GRAY};

      ${media.md`
        font-size: ${rem(21)};
        line-height: ${rem(29)}
      `}
    }

    ol, ul {
      padding-left: 30px;
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 10px;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: #fefefe;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
      background-origin: content-box;
      background-position: right -1rem center;
      background-repeat: no-repeat;
      background-size: 9px 6px;
      border: 1px solid #cacaca;
      border-radius: 3px;
      color: #0a0a0a;
      font-size: 1rem;
      height: 2.4375rem;
      line-height: normal;
      margin: 0 0 1rem;
      padding: .5rem;
      padding-right: 1.5rem;
      width: 100%;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .button {
      -webkit-appearance: none;
      background-color: #000;
      border: 1px solid transparent;
      border-radius: 0px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 0.725rem;
      line-height: 1;
      margin: 0 0 1rem;
      margin-left: auto;
      margin-right: auto;
      padding: .85em 1em;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
      text-decoration: none;
      transition: background-color .25s ease-out,color .25s ease-out;
      vertical-align: middle;
    }
  `,
  mainContent: css`
    margin-left: calc(0/12 * 100%);
    padding-bottom: 40px;
  `,
  title: css`
    font-size: ${rem(18)};
    line-height: ${rem(25)};
    margin-bottom: 20px;
    margin-top: 40px;

    &:after {
      background: ${CERULEAN};
      content: ' ';
      display: block;
      height: 1px;
      margin-top: 20px;
      width: 100%;
    }

    ${media.md`
      font-size: ${rem(32)};
      margin-bottom: 40px;
      margin-top: 60px;

      &:after {
        margin-top: 35px;
      }
    `}
  `
};
