import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Styles from './NavSidebar.styles';
import Text from '../../base/Text';
import { isValidArray, getKey, isValidString } from '../../../helpers';
import Link from '../../base/Link';

const LinkList = styled.ul`
  ${Styles.linkList}
`;
const LinkListItem = styled.li`
  ${Styles.linkListItem}
`;
const LinkComponent = styled(Link)`
  ${Styles.link}
`;
const Title = styled(Text)`
  ${Styles.title}
`;

/**
 * NavSidebar component
 * @returns {JSX}
 */
const NavSidebar = ({
  contents,
  location,
  title,
}) => {
  const renderLinks = useMemo(() => {
    if (!isValidArray(contents)) return null;

    const linkList = contents.map(item => {
      const uri = getKey(item, 'externalLink', getKey(item, 'page.uri'));
      const hasExternalLink = isValidString(getKey(item, 'externalLink'));
      const isActive = getKey(location, 'pathname') === uri;
      const label = getKey(item, 'page.title', getKey(item, 'name'));
      return (
        <LinkListItem key={item.id} isActive={isActive}>
          <LinkComponent href={uri} preloadDisabled={hasExternalLink}>
            {label}
          </LinkComponent>
        </LinkListItem>
      );
    });

    return (
      <LinkList>
        {linkList}
      </LinkList>
    );
  }, [contents, location]);

  return (
    <div>
      <Title tag="h3">{title}</Title>

      {renderLinks}
    </div>
  );
};

NavSidebar.propTypes = {
  contents: PropTypes.array,
  location: PropTypes.object,
  title: PropTypes.string,
};

export default NavSidebar;
