import React, {
  useState, useEffect, useRef, memo
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useBreakPoint, useIsMounted } from '../../../helpers/hooks';
import Styles from './Video.styles';

const VideoStyled = styled.video`${Styles.video}`; // prettier-ignore

/**
 * Video Component
 * @param {Object} props - components props
 * @param {Object} props.videoDesktop - desktop asset
 * @param {Object} props.videoMobile - mobile asset
 * @param {Object} props.videoRef - videoRef
 * @returns {JSX}
 */
const Video = ({
  backgroundImage, backgroundImageMobile, shouldPlay, videoDesktop, videoMobile,
}) => {
  const breakPoint = useBreakPoint();
  const isMounted = useIsMounted();
  const videoRef = useRef(null);
  const [videoAsset, setVideoAsset] = useState(breakPoint === 'sm' ? videoMobile : videoDesktop);

  useEffect(() => {
    if (isMounted) {
      setVideoAsset(breakPoint === 'sm' ? videoMobile : videoDesktop);
      // setPosterAsset(breakPoint === 'sm' ? backgroundImageMobile : backgroundImage);
    }
  }, [
    breakPoint,
    setVideoAsset,
    videoDesktop,
    videoMobile,
    isMounted,
    // setPosterAsset,
    backgroundImage,
    backgroundImageMobile
  ]);

  useEffect(() => {
    const videoRefCurrent = videoRef.current;

    if (videoRefCurrent && shouldPlay) {
      videoRefCurrent.defaultMuted = true;
      videoRefCurrent.play();
    } else if (videoRefCurrent && !shouldPlay) {
      videoRefCurrent.defaultMuted = true;
      videoRefCurrent.pause();
    }
  }, [shouldPlay, videoRef]);

  if (!isMounted) return null;
  // poster={getKey(posterAsset, 'src')}
  return (
    <VideoStyled muted playsInline autoPlay loop ref={videoRef}>
      <source src={`https://api.alestradev.com${videoAsset}`} type="video/mp4" />
    </VideoStyled>
  );
};

Video.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  shouldPlay: PropTypes.bool,
  videoDesktop: PropTypes.string,
  videoMobile: PropTypes.string,
};

export default memo(Video);
