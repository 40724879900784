import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isValidArray, getKey } from '../../../helpers';
import SliderPagination from './SliderPagination';
import Styles from './ScrollSlider.styles';

const Slider = styled.div`${Styles.slider}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * ScrollSlider component
 * @returns {JSX}
 */
const ScrollSlider = ({ children }) => {
  const options = [];
  const withSlider = children
    .filter(child => getKey(child, 'props.isVerticalSlide'))
    .map((child, index) => {
      options.push({
        gradient: getKey(child, 'props.cardGradient'),
        index,
        showSlideTitle: getKey(child, 'props.showSlideTitle'),
        title: getKey(child, 'props.title'),
      });
      const childFiltered = React.cloneElement(child, {
        index,
      });
      return (
        <Slider key={`sliderWrapper${getKey(child, 'props.id')}`}>
          {childFiltered}
        </Slider>
      );
    });
  const withoutSlider = children.filter(child => !getKey(child, 'props.isVerticalSlide'));

  return (
    <>
      {isValidArray(withSlider) && (
        <Wrapper>
          {withSlider}
          <SliderPagination options={options} />
        </Wrapper>
      )}
      {isValidArray(withoutSlider) && (
        <Slider shouldHide>{withoutSlider}</Slider>
      )}
    </>
  );
};

ScrollSlider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
};

export default ScrollSlider;
