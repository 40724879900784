/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { isValidArray, getKey } from '../../../../helpers';
import { gotoSlide } from '../../../../store/actions';
import { activeSlideSelector } from '../../../../store/selectors';
import Styles from './SliderPagination.styles';

const Circle = styled.div`${Styles.circle}`; // prettier-ignore
const Elements = styled.div`${Styles.elements}`; // prettier-ignore
const Line = styled.div`${Styles.line}`; // prettier-ignore
const MainWrapper = styled.div`${Styles.mainWrapper}`; // prettier-ignore
const Title = styled.div`${Styles.title}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore

/**
 * SliderPagination components
 * @param {Object} props - component props
 * @param {Array} props.options - circle options
 * @returns {JSX}
 */
const SliderPagination = ({ options }) => {
  const activeSlide = useSelector(activeSlideSelector);
  const dispatch = useDispatch();
  if (!isValidArray(options)) return null;

  const lastItem = options.length - 1;

  const circles = options.map((circle, index) => {
    const isLast = index === lastItem;
    const showSlideTitle = getKey(circle, 'showSlideTitle');
    const shouldDisplay = activeSlide === index && showSlideTitle;

    return (
      <Elements key={`SliderPaginationCircle${index}`}>
        <Circle
          startColor={getKey(circle, 'gradient.start', 'transparent')}
          endColor={getKey(circle, 'gradient.end', 'transparent')}
          shouldDisplay={activeSlide === index}
          onClick={() => dispatch(gotoSlide(index))}
        >
          {showSlideTitle && (
            <Title shouldDisplay={shouldDisplay}>{getKey(circle, 'title')}</Title>
          )}
        </Circle>
        {!isLast && (
          <Line />
        )}
      </Elements>
    );
  });

  return (
    <MainWrapper>
      <Wrapper>
        {circles}
      </Wrapper>
    </MainWrapper>
  );
};

SliderPagination.propTypes = {
  options: PropTypes.array,
};

export default SliderPagination;
