import { graphql } from 'gatsby';

import NavSidebar from '../../components/compound/NavSidebar';
import { getKey } from '../../helpers';

/**
 * internalnavigation widget definition
 * @param {Object} props - widget props
 * @param {string} widgetType - widget type
 * @returns {Object} widget definition
 */
export default function internalnavigation(props, widgetType) {
  return {
    Component: NavSidebar,
    contents: getKey(props, 'menuItems', []).map(c => ({
      externalLink: getKey(c, 'externalLink'),
      id: getKey(c, 'id'),
      name: getKey(c, 'name'),
      order: getKey(c, 'order'),
      page: {
        id: getKey(c, 'page.id'),
        title: getKey(c, 'page.title'),
        uri: getKey(c, 'page.uri'),
      },
    })),
    key: getKey(props, 'key'),
    title: getKey(props, 'title'),
    widgetOrder: getKey(props, 'widgetOrder'),
    widgetType,
  };
}

export const query = graphql`
  fragment InternalNavigation on StrapiPagesWidgets {
    internalnavigation {
      key: id
      menuItems {
        externalLink
        id
        name
        order
        page {
          id
          title
          uri: slug
        }
      }
      title
    }
  }
`;
