import { useStaticQuery, graphql } from 'gatsby';
import { isValidArray, getKey } from '../..';

const CATEGORIES = [
  'Conectividad',
  'Colaboración',
  'Ciberseguridad',
  'Redes Administradas',
  'Integración de Sistemas',
  'Nube',
  'Transformación Digital',
  'Movilidad'
];

export const CLIENT_CATEGORIES = [
  {
    name: '¿Eres Cliente Alestra?',
    value: '',
  },
  {
    name: 'Sí',
    value: 'Sí',
  },
  {
    name: 'No',
    value: 'No',
  },
];

export const CARGO_CATEGORIES = [
  {
    name: 'Cargo',
    value: '',
  },
  {
    name: 'Gerente',
    value: 'Gerente',
  },
  {
    name: 'Director',
    value: 'Director',
  },
  {
    name: 'Especialista',
    value: 'Especialista',
  },
  {
    name: 'Jefe',
    value: 'Jefe',
  },
  {
    name: 'Analista',
    value: 'Analista',
  },
  {
    name: 'Asistente',
    value: 'Asistente',
  },
  {
    name: 'Independiente',
    value: 'Independiente',
  },
];

/**
 * useContactCategories hooks
 * @returns {Object}
 */
export default function useContactCategories() {
  const contactOptions = CATEGORIES.map(item => ({
    name: item,
    value: item,
  }));

  return [
    {
      name: '¿En qué servicio estás interesado?',
      value: '',
    },
    ...contactOptions,
  ];
}

/**
 * useContactCategories hooks
 * @returns {Object}
 */
export function useContactCategoriesLegacy() {
  const contactOptions = useStaticQuery(
    graphql`
      query {
        data: allStrapiSolutioncategory(
          sort: {
            fields: name
        }) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `
  );

  const contact = getKey(contactOptions, 'data.edges');
  const contactFiltered = isValidArray(contact)
    ? contact.map(item => ({
      name: item.node.name,
      value: item.node.name,
    }))
    : {};

  return [
    {
      name: 'Interesado en',
      value: '',
    },
    ...contactFiltered,
  ];
}
