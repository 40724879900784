import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import defaultBg from '../../../../static/images/banner-home.jpg';
import ImageFluid from '../ImageFluid';
import ImageFluidWrapper from '../ImageFluidWrapper';

import Styles from './Banner.styles';

const Wrapper = styled.div`
  ${Styles.wrapper}
`;
const PlaceHolderDesktop = styled(ImageFluid)`
  ${Styles.placeHolderDesktop}
`;
const PlaceHolderMobile = styled(ImageFluid)`
  ${Styles.placeHolderMobile}
`;

/**
 * Banner component
 * @returns {JSX}
 */
const Banner = ({
  backgroundImage,
  backgroundImageMobile,
}) => {
  return (
    <Wrapper backgroundImage={defaultBg}>
      <ImageFluidWrapper>
        {backgroundImage && <PlaceHolderDesktop fluid={backgroundImage} />}
        {backgroundImageMobile && <PlaceHolderMobile fluid={backgroundImageMobile} />}
      </ImageFluidWrapper>
    </Wrapper>
  );
};

Banner.propTypes = {
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
};

export default Banner;
