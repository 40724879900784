import { css } from 'styled-components';
import { rem } from '../../../../helpers/mixins';
import { MID_GRAY, SILVER } from '../../../../constants/colors';

export default {
  container: css`
    margin-bottom: 40px;
  `,
  date: css`
    color: ${MID_GRAY};
    font-size: ${rem(15)};
    line-height: ${rem(24)};
  `,
  description: css`
    color: ${SILVER};
    font-size: ${rem(15)};
    line-height: ${rem(24)};
  `,
  title: css`
    font-size: ${rem(18)};
    line-height: ${rem(25)};
    margin-bottom: 20px;
    margin-top: 30px;
  `,
};
